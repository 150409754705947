import { SecondaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { getDifferenceInMonths } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { graphql } from 'gatsby';
import React from 'react';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { usePolicyState } from 'state/policy/state';
import FormHeading from 'components/Forms/FormHeading';
import { InfoCardsCsContent } from 'components/InfoCards';
import Layout from 'components/Layout';
import { processMandatoryCta, processOptionalCta } from 'helpers/csTypeProcessors';
import { useAnalyticsKnockoutPageVariables } from 'helpers/useAnalyticsKnockoutPageVariables';
import { CsCta } from 'types/contentStack';
import { StyledContainer, StyledInfoCards } from './styles';

type KnockoutPageProps = {
  data: KnockoutPageData;
};

const KnockoutPage: React.FC<KnockoutPageProps> = ({ data }) => {
  const { policy } = usePolicyState();
  const page = data.csKnockoutPage;
  const [replaceDriverState] = useReplaceDriver();
  const addOrReplaceDriver =
    replaceDriverState.replacedDriverRef === null ? 'Add driver' : 'Replace driver';

  const infoCards =
    page.info_cards?.length > 0 ? page.info_cards[0].info_cards.info_cards : undefined;
  const cta = processOptionalCta(page.cta, 'Body');
  useAnalyticsKnockoutPageVariables({
    pageCategories: [addOrReplaceDriver, 'knockout'],
    pageType: 'KnockoutPage',
    userId: policy?.policyDetails.policyNumber ?? undefined,
    policyExpiry: policy?.policyDetails.renewalDate
      ? policy.policyDetails.renewalDate.toUTCString()
      : undefined,
    policyTimeRemaining: policy?.policyDetails.renewalDate
      ? getDifferenceInMonths(new Date(), policy.policyDetails.renewalDate).toString()
      : undefined,
    knockoutPage: true,
    knockoutReason: data.csKnockoutPage.rich_text,
  });

  return (
    <Layout
      pageType="form"
      meta={{ title: page.meta_title }}
      showAlertNotification={page.show_alert_notification ?? undefined}>
      <FormHeading text={page.heading} />
      <RichText html={page.rich_text} />
      {infoCards && <StyledInfoCards cards={infoCards} alignLeft />}
      {cta && (
        <StyledContainer>
          <SecondaryCta cta={processMandatoryCta(page.cta, 'Body')} />
        </StyledContainer>
      )}
    </Layout>
  );
};

export default KnockoutPage;

export type KnockoutPageData = {
  csKnockoutPage: {
    meta_title: string;
    page_id: string;
    heading: string;
    rich_text: string;
    info_cards: [InfoCardsCsContent];
    cta: [CsCta];
    show_alert_notification: boolean | null;
  };
};

export const query = graphql`
  fragment KnockoutPage on cs__knockout_page {
    meta_title
    page_id
    heading
    rich_text
    show_alert_notification
    info_cards {
      ...InfoCardsKnockout
    }
    cta {
      display_text
      screen_reader_text
      url
      open_in_new_tab
    }
  }
`;
