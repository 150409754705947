import sha256 from 'crypto-js/sha256';
import isMobile from 'is-mobile';
import { useEffect } from 'react';
import { trackEvent } from './eventTracking';

export type AnalyticsPageVariables = {
  pageCategories: string[];
  pageType: string;
  pageError?: number;
  userId?: string;
  policyExpiry?: string;
  policyTimeRemaining?: string;
  knockoutPage: boolean;
  knockoutReason: string;
};

export const useAnalyticsKnockoutPageVariables = (
  analyticsPageVariables: AnalyticsPageVariables
): void => {
  const userId = analyticsPageVariables.userId
    ? sha256(analyticsPageVariables.userId).toString()
    : undefined;

  useEffect(
    () => {
      if (userId)
        trackEvent({
          event: 'pageVars',
          pageCategories: analyticsPageVariables.pageCategories,
          pageType: analyticsPageVariables.pageType,
          pageError: analyticsPageVariables.pageError,
          isMobile: isMobile({ featureDetect: true }),
          userId,
          policyExpiry: analyticsPageVariables.policyExpiry,
          policyTimeRemaining: analyticsPageVariables.policyTimeRemaining,
          knockoutPage: analyticsPageVariables.knockoutPage,
          knockoutReason: analyticsPageVariables.knockoutReason,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyticsPageVariables.policyExpiry, analyticsPageVariables.policyTimeRemaining, userId]
  );
};
