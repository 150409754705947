import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, { spacingWithBorder } from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import InfoCards from 'components/InfoCards';

export const StyledHeading = styled.h2`
  ${fonts.headingMedium};
`;

export const StyledContainer = styled(PageWidthContainer)`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(8)};
  `}

  border: 1px solid ${colors.neutral03};
  border-width: 1px 0;

  padding-top: ${spacingWithBorder(4, 1)};
  padding-bottom: ${spacingWithBorder(4, 1)};
`;

export const StyledInfoCards = styled(InfoCards)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(8)};
  `}
`;
